import $ from 'jquery'
import { toast } from '@/modules/notifications'

// used in listing
// trigged on listing mount
let productLoaded = {}

// called every times that user want to open popup
function onPopupOpen ($domElement) {
  // get product by
  let $addToWishlistButton = $domElement.find('.add-to-wishlist')
  let productSku = $addToWishlistButton.data('sku')
  let $store = global.$store
  return getProductBySku($store, productSku)
    .then((product) => {
      $addToWishlistButton.toggleClass('active', !!isOnWishlist($store, product))
    })
    .finally(() => {
      return true
    })
}

// get product by sku
// sku is stored on dom of product
// use a map to prevent ajax call every time
// map is based using sku of the product as key
function getProductBySku ($store = global.$store, sku) {
  if (productLoaded && productLoaded[sku]) {
    return Promise.resolve(productLoaded[sku])
  } else {
    return $store.dispatch('product/load', sku)
      .then((product) => {
        product = loadSingleVariantByProduct(product)
        productLoaded[sku] = product
        return productLoaded[sku]
      })
      .catch(res => {
        const msg = res.body && res.body.result ? res.body.result : 'GenericError'
        toast.error(global.$i18n.t(msg))
      })
  }
}

// add or remove by condition
// condition: add if is not in wishlst
// otherwise remove
function wishlistManager ($store, product, $element) {
  let promiseArr = []
  let isOnWish = isOnWishlist($store, product)
  if (isOnWish) {
    promiseArr.push(removeToWishlist($store, isOnWish))
  } else {
    promiseArr.push(addToWishlist($store, product))
  }
  $store.commit('ui/showLoader')
  return Promise.all(promiseArr)
    .then(() => {
      $element.toggleClass('active', !isOnWish)
    })
    .finally(() => {
      $store.commit('ui/hideLoader')
    })
}

// add element on wishlist
function addToWishlist ($store, product) {
  return $store.dispatch('user/addToWishlist', { product: product, filters: oneSizeOption(product) })
    .catch(res => {
      const msg = res.body && res.body.result ? res.body.result : 'GenericError'
      toast.error(global.$i18n.t(msg))
      return false
    })
    .then(() => {
      toast.success(global.$i18n.t('product-added-to-wishlist'))
      return true
    })
}

// remove element from wishlist
function removeToWishlist ($store, product) {
  return $store.dispatch('user/removeFromWishlist', product.id)
    .catch(res => {
      const msg = res.body && res.body.result ? res.body.result : 'GenericError'
      toast.error(global.$i18n.t(msg))
      return false
    })
    .then(() => {
      toast.success(global.$i18n.t('product-removed-to-wishlist'))
      return true
    })
}

// Check if produc is on wishlist
// if yes add class is-active on $element
function isOnWishlist ($store, product) {
  // otherwise check if product exist on wishlist
  // get his simple
  // because wishlist contain sku of simple
  if (product && product['simple-sku'] && $store.getters['user/isLogged']) {
    return $store.getters['user/isOnWishlist']({ sku: product['simple-sku'] })
  } else {
    return false
  }
}

// get product varint by sku
// used with product with only one variant
// return sku of variant
function loadSingleVariantByProduct (product) {
  if (product && product['simple-sku']) {
    return product
  } else {
    let variant = oneSizeVariant(product)
    if (variant) {
      product['simple-sku'] = variant.sku
    }
    return product
  }
}

// used by product with one size only
// get its configurable options
// and create variants to add into wishlist
// return object of size option
function oneSizeOption (product) {
  let configurableOptionBySize = product.configurable_options.find((el) => {
    return el.attribute_code === 'size'
  })

  if (configurableOptionBySize) {
    return { [configurableOptionBySize.attribute_id]: configurableOptionBySize.values[0].value_index }
  } else {
    return false
  }
}

// get first variant info from product object
// used with product with only one variant
function oneSizeVariant (product) {
  if (product && product.one_size === 1 && product.variants) {
    return product.variants[0].product
  } else {
    return product
  }
}

export function initLookBook () {
  if (process.client) {
    $(document).ready(function () {
      const $store = global.$store
      const $lookbookContainer = $('.lookbook-container')
      const $mainImage = $('.lookbook-image')
      const $pinTipes = $('.pin__type')
      const $pinTipe = $lookbookContainer.find('.pin__type .pin-label')
      const $pinImage = $lookbookContainer.find('.pin__image')
      const $addAll = $('button.addallbutton')
      const $addToWishlist = $lookbookContainer.find('.add-to-wishlist')
      const $lookbook = $('.mgs-lookbook-container')

      if ($pinTipes.length) {
        // $pinTipe.hover(function () {
        //   $pinImage.toggleClass('pm-mask')
        // })

        $pinTipe.unbind().click(function () {
          let $parent = $(this).parent()
          if ($parent.hasClass('pin__opened')) {
            // was open
            // close it
            $pinTipes.removeClass('pin__opened')
          } else {
            $pinTipes.removeClass('pin__opened')
            $parent.addClass('pin__opened')
            onPopupOpen($parent)
          }
        })

        $mainImage.click(function () {
          $pinTipe.removeClass('pin__opened')
        })

        $pinTipes.each(function (index) {
          let $href = $(this).find('.wrapp-actions a')
          $href.text(global.$i18n.t('Discover more'))
          if ($(this).find('div.pin__title').hasClass('addable')) {
            $(this).addClass('canaddall')
          }
        })

        $addToWishlist.each(function () {
          // hide button only if product is one-size
          if ($(this).data('one-size') === 0) {
            $(this).hide()
          }
        })

        $addToWishlist.on('click', function () {
          // get sku and id
          let productSku = $(this).data('sku')
          // add to wishlist work only for logged user
          // check if before
          let isLogged = $store.getters['user/isLogged']
          if (!isLogged) {
            const componentToLoad = import('@/theme/components/RightNavigation/Auth/Login.vue')
            return $store.dispatch('ui/loadActiveComponent', { component: componentToLoad })
          } else {
            return getProductBySku($store, productSku)
              .then((product) => {
                return wishlistManager($store, product, $(this))
              })
          }
        })

        $addAll.hover(function () {
          $pinImage.toggleClass('pm-mask')
          $lookbook.toggleClass('pm-addall')
        })
      }
    })
  }
}
